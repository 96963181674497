import fs from 'fs';
import path from 'path';
import { fetchApiWithRetries, checkIsDevelopment } from './_helpers';

export async function getAmplitudePageTypes() {
  const cachedPath = path.resolve('./cache/amplitude-page-types.json');
  try {
    const cachedFile = fs.readFileSync(cachedPath);
    return JSON.parse(cachedFile);
  } catch (e) {}

  const isDevelopment = checkIsDevelopment();
  const stati = isDevelopment ? '[PUBLISH, DRAFT]' : '[PUBLISH]';

  const allPages = await fetchApiWithRetries(
    `
    query Page {
      pages(first: 300, where: {stati: ${stati}}) {
        nodes {
          slug
          uri
          meta
        }
      }
    }
  `
  );

  const amplitudePageTypes = {};

  allPages.pages.nodes.forEach((page) => {
    if (page.meta) {
      const pageMeta = page.meta ? JSON.parse(page.meta) : null;

      if (pageMeta && pageMeta.page) {
        const amplitudePageType = pageMeta.page._amplitudePageType;
        amplitudePageTypes[page.slug] = amplitudePageType || null;
      }
    }
  });

  fs.writeFile(cachedPath, JSON.stringify(amplitudePageTypes), (err) => {
    if (err) {
      console.error(err);
    }
  });

  return amplitudePageTypes;
}
