import {
  createInstance,
  OptimizelyDecideOption,
} from '@optimizely/optimizely-sdk/dist/optimizely.lite.min.js';
import { userAgent, NextResponse, NextRequest } from 'next/server';
import Cookies from 'universal-cookie';
import * as CryptoJS from 'crypto-js';
import { Client as OptimizelyClient } from '@optimizely/optimizely-sdk/dist/shared_types';
import { FormType } from '@rippling/utils';
import {
  getRequestCookieValue,
  setResponseCookieValue,
} from ':helpers/cookie-helpers';
import { isNonEmptyArray, isNonEmptyString } from ':helpers/validation-helpers';

export type OptimizelyExperiment = {
  status: string;
  audienceConditions: string[];
  audienceIds: string[];
  variations: [
    {
      variables: [];
      id: string;
      key: string;
      featureEnabled: false;
    }
  ];
  key: string;
};

export type OptimizelyAudience = {
  id: string;
  conditions: string;
  name: string;
};

export type OptimizelyTypedAudience = {
  name: string;
  conditions: any[];
  id: string;
};

type OptimizelyFeatureFlag = {
  id: string;
  key: string;
  rolloutId: string;
  experimentIds: string[];
  variables: any[];
};

export type OptimizelyDatafile = {
  environmentKey: string;
  experiments: OptimizelyExperiment[];
  audiences: OptimizelyAudience[];
  typedAudiences: OptimizelyTypedAudience[];
  featureFlags: OptimizelyFeatureFlag[];
};

export enum ExperimentDecisions {
  Off = 'off',
  OutOfExperiment = 'out-of-experiment',
  Control = 'control',
}

const VERCEL_EDGE_CLIENT_ENGINE = 'javascript-sdk/vercel-edge';

export const VERCEL_OPTIMIZELY_API_PATH = '/api/www-vercel-optimizely-events';
export const EXPERIMENT_DATA_PATH = '/utility/experiments';

const OPT_PATHS_COOKIE_KEY = 'opt_paths';

const makeExperimentUserIdKey = (featureFlagKey: string) => {
  return `opt_${featureFlagKey}_user`;
};

const makeExperimentVariationKey = (featureFlagKey: string) => {
  return `opt_${featureFlagKey}_variation`;
};

export const isExperimentDataPath = (path: string) => {
  return path === EXPERIMENT_DATA_PATH;
};

export const isVercelOptimizelyPath = (path: string) => {
  return path === VERCEL_OPTIMIZELY_API_PATH;
};

const variationIsOff = (key: ExperimentDecisions) => {
  return [
    ExperimentDecisions.Off,
    ExperimentDecisions.Control,
    ExperimentDecisions.OutOfExperiment,
  ].includes(key);
};

const getUrlParam = (searchStr: string, name: string): string => {
  const searchParamsRegexp = `[?&]${name}=([^&#]*)`;
  const results = new RegExp(searchParamsRegexp).exec(searchStr);

  if (!isNonEmptyArray(results)) {
    return '';
  }

  const matchVal = results?.[1] || '';

  return decodeURIComponent(matchVal);
};

const persistedParams = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
  'aclid',
  'gclid',
  'fbclid',
];

const getPersistedParamString = (req: NextRequest) => {
  return persistedParams.reduce((accum, param) => {
    const cookiedParam = req.cookies.get(param);
    if (cookiedParam) {
      const { name, value } = cookiedParam;
      accum += `${name}=${value}`;
    }
    return accum;
  }, '');
};

// Audiences that reference new attributes need to be configured here
const buildAttributes = (req: NextRequest, result, conditions) => {
  const isExistingCustomer = !!req.cookies.get('ExistingCustomer')?.value;

  conditions.forEach((condition) => {
    if (Array.isArray(condition)) {
      buildAttributes(req, result, condition);
    } else if (typeof condition === 'object') {
      if (condition.name === 'path') {
        result.path = req.nextUrl.pathname;
      }

      if (condition.name === 'params') {
        const persistedParamString = getPersistedParamString(req);

        result.params = `${persistedParamString} ${req.nextUrl.search}`;
      }

      if (condition.name === 'isExistingCustomer') {
        result.isExistingCustomer = isExistingCustomer || false;
      }

      if (condition.name === 'locale') {
        result.locale = req.nextUrl.locale;
      }
    }
  });
};

const getLocalParamFromAudiences = (audiences) => {
  const { conditions } = audiences?.[0] || {};
  let localeParamValue;

  const recurse = (conditions) => {
    conditions.forEach((condition) => {
      if (Array.isArray(condition)) {
        recurse(condition);
      } else if (typeof condition === 'object') {
        if (condition.name === 'locale') {
          localeParamValue = condition.value;
        }
      }
    });
  };

  recurse(conditions);

  return localeParamValue;
};

const getAudiences = (
  datafile: OptimizelyDatafile,
  experiment: OptimizelyExperiment
): (OptimizelyTypedAudience | OptimizelyAudience)[] => {
  const { typedAudiences, audiences } = datafile;

  return experiment.audienceIds.reduce((foundAudiences, audienceId) => {
    const audience =
      // prefer typed audiences
      typedAudiences.find((typedAud) => typedAud.id === audienceId) ||
      // look in audiences if not found yet
      audiences.find((standardAud) => standardAud.id === audienceId);

    if (audience) {
      foundAudiences.push(audience);
    }

    return foundAudiences;
  }, []);
};

const getAttributes = (req: NextRequest, audiences, ua) => {
  const { conditions } = audiences?.[0] || {};
  const attributes = { $opt_user_agent: ua };
  buildAttributes(req, attributes, conditions);

  return attributes;
};

const getTargeting = (
  audiences: (OptimizelyTypedAudience | OptimizelyAudience)[]
) => {
  return audiences.map(({ conditions }) => conditions).flat();
};

// Get all the paths from the targeting audience for server rewrite
const getTargetingPaths = (conditions: any[]) => {
  let result = [];

  conditions.forEach((condition) => {
    if (Array.isArray(condition)) {
      result = result.concat(getTargetingPaths(condition));
    } else if (typeof condition === 'object') {
      const pathObjs = conditions
        .filter(
          ({ name, value }) =>
            name === 'path' && !!value && value !== '$opt_dummy_value'
        )
        .map(({ value }) => value);
      result = result.concat(pathObjs);
    }
  });

  return result;
};

const validateOptimizelyDatefile = (datafile: OptimizelyDatafile) => {
  if (typeof datafile.environmentKey !== 'string') {
    return false;
  }

  if (!Array.isArray(datafile.experiments)) {
    return false;
  }

  if (!Array.isArray(datafile.audiences)) {
    return false;
  }

  return true;
};

const parseAudienceConditions = (datafile: OptimizelyDatafile) => {
  datafile.audiences = datafile.audiences.map((audience) => {
    audience.conditions = JSON.parse(audience.conditions);

    return audience;
  });
};

const fetchDataFile = async (req: NextRequest): Promise<OptimizelyDatafile> => {
  try {
    const response = await fetch(`${req.nextUrl.origin}/utility/experiments`);
    const text = await response.text();
    const json = text.match(/id="json">(.*?)</)[1];
    const datafile = JSON.parse(json);

    if (!validateOptimizelyDatefile(datafile)) {
      throw new Error('Invalid datafile fetched');
    }

    return datafile;
  } catch (err) {
    console.error('Error fetching Optimizely datafile', err);
    return null;
  }
};

const getOptPathsFromCookie = (req: NextRequest): string[] | null => {
  try {
    // attempt to get opt paths from cookie
    const cookiePaths = getRequestCookieValue(req, OPT_PATHS_COOKIE_KEY, false);

    // return parsed paths only if its an array
    if (Array.isArray(cookiePaths)) {
      return cookiePaths;
    }
  } catch {
    // This usually happens when the user doesnt have the cookie set. And thats ok... (no logging necessary)
  }
  return null;
};

const getOffsetMinutesTimeStamp = (minutes: number) => {
  const minutesMS = minutes * 60 * 1000;
  return new Date(Date.now() + minutesMS);
};

const get30MinuteTimestamp = () => getOffsetMinutesTimeStamp(30);

const getOptimizelyClient = (
  datafile: OptimizelyDatafile,
  ev
): OptimizelyClient => {
  return createInstance({
    datafile: JSON.stringify(datafile),
    clientEngine: VERCEL_EDGE_CLIENT_ENGINE,
    eventDispatcher: {
      dispatchEvent: ({ url, params }) => {
        ev.waitUntil(
          fetch(url, {
            method: 'POST',
            body: JSON.stringify(params),
          })
        );
      },
    },
  });
};

const getExperimentUserId = (req: NextRequest, featureFlagKey: string) => {
  const experimentUserIdKey = makeExperimentUserIdKey(featureFlagKey);
  return getRequestCookieValue(req, experimentUserIdKey, false) || null;
};

const setExperimentUserId = (
  res: NextResponse,
  featureFlagKey: string,
  experimentUserId: string
) => {
  const experimentUserIdKey = makeExperimentUserIdKey(featureFlagKey);
  return setResponseCookieValue(res, experimentUserIdKey, experimentUserId);
};

const getExperimentVariationKey = (
  req: NextRequest,
  featureFlagKey: string
) => {
  const experimentUserVariationKey = makeExperimentVariationKey(featureFlagKey);
  return getRequestCookieValue(req, experimentUserVariationKey, false) || null;
};

const setExperimentVariationKey = (
  res: NextResponse,
  featureFlagKey: string,
  experimentUserId: string
) => {
  const experimentUserVariationKey = makeExperimentVariationKey(featureFlagKey);
  return setResponseCookieValue(
    res,
    experimentUserVariationKey,
    experimentUserId
  );
};

const setProcessingCookie = (
  req: NextRequest,
  res: NextResponse,
  featureFlagKey: string,
  attributes: any
) => {
  const cookieKey = `opt_${featureFlagKey}_processing`;
  const processingCookie = getRequestCookieValue(req, cookieKey, false);
  if (processingCookie === false) {
    return;
  }

  return setResponseCookieValue(res, cookieKey, attributes);
};

const setOptPathsCookie = (res: NextResponse, value: string[]) => {
  return setResponseCookieValue(
    res,
    OPT_PATHS_COOKIE_KEY,
    value,
    get30MinuteTimestamp()
  );
};

const generateUUIDFromIP = (req) => {
  const ipAddress = req.ip;
  const secretKey = 'dasjf9a12&(@*EY9c';

  const combinedData = ipAddress + secretKey;

  // Hash the combined data using SHA-256 from crypto-js
  const hash = CryptoJS.SHA256(combinedData);

  // Create a UUID from the hashed result
  const uuid = hash
    .toString(CryptoJS.enc.Hex)
    .replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-4$3-a$4-$5');

  return uuid;
};

const getFeatureFlagKey = (experimentKey: string) => {
  return experimentKey.replace(/_experiment$/, '');
};

const getVariantPageSlug = (routePath: string, variationKey: string) => {
  const pageSlug = routePath === '/' ? 'home' : routePath;
  return `${pageSlug}-${variationKey}`;
};

const getVariantPageUrl = (req: NextRequest, variantPageSlug: string) => {
  const url = req.nextUrl.clone();
  url.pathname = variantPageSlug;

  return new URL(url);
};

const isDefaultRolloutRuleKey = (ruleKey: string) => {
  return /default-rollout/.test(ruleKey);
};

const getOptPathsFromDatafile = (datafile: OptimizelyDatafile) => {
  let optPaths = [];

  datafile.experiments.forEach((experiment) => {
    if (experiment?.status !== 'Running') {
      return;
    }

    const featureFlagKey = getFeatureFlagKey(experiment.key);

    // experiment rule key needs to match a feature flag key, or we'll get the dreaded not found error (below)
    // [OPTIMIZELY] - ERROR 2023-08-07T17:44:38.527Z OPTIMIZELY: Feature key <rule set key> is not in datafile.
    /**
     * This error is thrown bc we always attempt to use experiment.key (ruleset key) as the feature flag key (inside of getUserVariationKey).
     * This is inherently wrong. We can reference the literal feature flag key from the datafile using the experiment.id, and looking it up in
     * datafile.featureFlags[n].experimentIds **** THIS SHOULD BE DONE ON THE FILE-PARSE, NOT IN MIDDLEWARE (V2) ****
     */
    if (
      !datafile.featureFlags.some(
        ({ key }) =>
          key === featureFlagKey || experiment.key === 'home_page_traffic_split'
      )
    ) {
      console.error(
        `\nExperiment ruleset key does not match a feature flag configuration: "${experiment.key}". Please revise the configuration.\n`
      );
      return;
    }

    const audiences = getAudiences(datafile, experiment);

    // if audiences is empty or failed to build for an experiment
    if (!isNonEmptyArray(audiences)) {
      console.error(
        `\nFailed to build audiences for ${experiment?.key}, omitting from opt_paths cookie\n`
      );
      return;
    }
    const targeting = getTargeting(audiences);
    const paths = getTargetingPaths(targeting);

    optPaths = optPaths.concat(paths);
  });

  return optPaths;
};

const getUserVariationKey = (
  optimizelyClient: OptimizelyClient,
  featureFlagKey: string,
  experimentUserId: string,
  attributes: any
): ExperimentDecisions | string => {
  const userContext = optimizelyClient.createUserContext(
    experimentUserId,
    attributes
  );

  /**
   * For some reason Optimizely will give an "off" variationKey value for out-of-ramp and
   * control decisions. This is unfortunate because then we need to figure out which is which.
   */
  const { ruleKey, variationKey } = userContext.decide(featureFlagKey, [
    OptimizelyDecideOption.DISABLE_DECISION_EVENT,
  ]);

  // a default rollout rulekey indicates the decision is "out of ramp" or "out of experiment"
  if (isDefaultRolloutRuleKey(ruleKey)) {
    return ExperimentDecisions.OutOfExperiment;
  }

  // by now we know we're in the experiment, so it'll be "control" or "variation_*" from now on
  if (variationKey === ExperimentDecisions.Off) {
    return ExperimentDecisions.Control;
  }

  // this should ALWAYS be variation_<some-slug-value>
  return variationKey;
};

const runPageExperiment = (
  optimizelyClient: OptimizelyClient,
  datafile: OptimizelyDatafile,
  req: NextRequest,
  res: NextResponse
) => {
  const { ua } = userAgent(req);
  const routePath = req.nextUrl.pathname;
  const locale = req.nextUrl.locale;

  // this should be referenced from Parsed File (V2) (parsedFile.activeExperiments)
  for (let i = 0; i < datafile.experiments.length; i++) {
    const experiment = datafile.experiments[i];
    if (experiment?.status !== 'Running') {
      continue;
    }

    // this should be referenced from Parsed File (V2)
    const audiences = getAudiences(datafile, experiment);

    if (!isNonEmptyArray(audiences)) {
      console.error(
        `Failed to build audiences for ${experiment?.key}, skipping ${experiment.key}`
      );
      continue;
    }

    const attributes = getAttributes(req, audiences, ua);

    // this should be referenced from Parsed File (V2)
    const targeting = getTargeting(audiences);

    // this should be referenced from Parsed File (V2)
    const targetingPaths = getTargetingPaths(targeting);

    // this should be referenced from Parsed File (V2)
    const pathIsInExperiment = targetingPaths.some(
      (path) => path === routePath
    );

    // if path is not in this experiment, continue to the next
    if (!pathIsInExperiment) {
      continue;
    }

    // locale normally matches requires target locale (already decided users)
    const localParamFromAudiences = getLocalParamFromAudiences(audiences);

    if (localParamFromAudiences && localParamFromAudiences !== locale) {
      continue;
    }

    const featureFlagKey = getFeatureFlagKey(experiment.key);

    let experimentUserId = getExperimentUserId(req, featureFlagKey);
    let variationKey = getExperimentVariationKey(req, featureFlagKey);

    // if no user id has been set, lets register the user and make a decision
    if (!experimentUserId || !variationKey) {
      // set a user uuid
      if (!experimentUserId) {
        experimentUserId = generateUUIDFromIP(req);
      }

      // we always set a new variation key in this case because it is either missing,
      // or does not belong to our user id
      variationKey = getUserVariationKey(
        optimizelyClient,
        featureFlagKey,
        experimentUserId,
        attributes
      );
      // set cookies for future reuse - we dont want to lose any new user ids or variations
      setExperimentUserId(res, featureFlagKey, experimentUserId);
      setExperimentVariationKey(res, featureFlagKey, variationKey);
      setProcessingCookie(req, res, featureFlagKey, attributes);
    }

    // if the variation key for our user is "off" of "control", we can continue to the next experiment
    if (variationIsOff(variationKey)) {
      continue;
    }

    // this will get a Wordpress slug variant: `variation_a` -> 'a'
    const variationSlug = variationKey.replace('variation_', '');

    if (!isNonEmptyString(variationSlug)) {
      continue;
    }

    // validate that the variation key is valid (configured)
    // const variationIsInExperiment = experiment.variations.some(
    //   ({ key }) => variationKey === key
    // )

    // if the variation key is invalid, or variation is disabled, skip.
    // if (!variationIsInExperiment) continue

    // get variant page rewrite data
    const variantPageSlug = getVariantPageSlug(routePath, variationSlug);
    const pageUrl = getVariantPageUrl(req, variantPageSlug);

    // rewrite variant as page
    res = NextResponse.rewrite(pageUrl);

    // set cookies for future reuse
    setExperimentUserId(res, featureFlagKey, experimentUserId);
    setExperimentVariationKey(res, featureFlagKey, variationKey);
    setProcessingCookie(req, res, featureFlagKey, attributes);
  }

  return res;
};

const trackConversions = (
  req: NextRequest,
  optimizelyClient: OptimizelyClient,
  datafile: OptimizelyDatafile
) => {
  const searchStr = req.nextUrl.search;
  const eventKeys = getUrlParam(searchStr, 'eventKeys').split(/,/g);

  const { ua } = userAgent(req);

  datafile.experiments.forEach((experiment) => {
    const audiences = getAudiences(datafile, experiment);
    if (!isNonEmptyArray(audiences)) {
      console.error(
        `Failed to build audiences for ${experiment?.key}, skipping conversion tracking.`
      );
      return;
    }
    const attributes = getAttributes(req, audiences, ua);
    const featureFlagKey = getFeatureFlagKey(experiment.key);
    const userId = getExperimentUserId(req, featureFlagKey);

    if (userId && eventKeys) {
      eventKeys.forEach((eventKey) => {
        optimizelyClient.track(eventKey, userId, attributes[featureFlagKey]);
      });
    }
  });
};

const reqPathIsInOptPaths = (req: NextRequest, optPaths: string[]) => {
  return optPaths.includes(req.nextUrl.pathname);
};

const shouldReturnEarly = (req: NextRequest) => {
  // If the path processed in middleware because of prefetching, don't process as an experiment
  // const purpose = req.headers.get('purpose')
  // if (purpose && purpose.match(/prefetch/i)) return true

  // if cookie opt paths has not been set, DO NOT RETURN EARLY
  const cookieOptPaths = getOptPathsFromCookie(req);
  if (!cookieOptPaths) {
    return false;
  }

  // we always want to process the vercel/optimizely path. DO NOT RETURN EARLY
  if (isVercelOptimizelyPath(req.nextUrl.pathname)) {
    return false;
  }

  // if path is in experiment (in cookied paths), DO NOT RETURN EARLY
  if (reqPathIsInOptPaths(req, cookieOptPaths)) {
    return false;
  }

  // if cookie paths is set, AND this path is not in an experiment, AND its not the vercel/optimizely path: RETURN EARLY
  // theres nothing to do here...
  return true;
};

export const runOptimizelyMiddleware = async (req, res, ev) => {
  // should we return early? (req path not in any experiments?)
  if (shouldReturnEarly(req)) {
    return res;
  }

  // fetch datafile
  const datafile = await fetchDataFile(req);

  if (!datafile) {
    // If datafile failed to fetch, set opt_paths to empty [] so that it doesn't continue fetching
    setOptPathsCookie(res, []);
    return res;
  }

  const optimizelyClient = getOptimizelyClient(datafile, ev);

  // We meed datafile.audiences[x].conditions to be parsed like in datafile.typedAudiences. We can't do this sooner though
  // since getting the Optimizely client requires the datafile to be in its native form.
  parseAudienceConditions(datafile);

  // Conversions
  if (isVercelOptimizelyPath(req.nextUrl.pathname)) {
    trackConversions(req, optimizelyClient, datafile);
  }

  const optPaths = getOptPathsFromDatafile(datafile);

  // if request is not in opt paths - this can happen on first optPaths fetch
  if (!reqPathIsInOptPaths(req, optPaths)) {
    // Set all opt paths for future reference
    setOptPathsCookie(res, optPaths);
    return res;
  }

  // Page experiments
  res = runPageExperiment(optimizelyClient, datafile, req, res);

  // Set all opt paths for future reference
  setOptPathsCookie(res, optPaths);

  return res;
};

export const getOptimizelyExperimentNames = (): string[] => {
  const cookiesHelper = new Cookies();

  const allCookies = cookiesHelper.getAll();

  return Object.keys(allCookies).reduce((accum, key) => {
    const experimentName = key.match(/opt_(.*)_user/)?.[1];
    if (experimentName) {
      accum.push(experimentName);
    }

    return accum;
  }, []);
};

export const getOptimizelyExperimentVariation = (
  experimentName: string | null
) => {
  const cookiesHelper = new Cookies();

  return cookiesHelper.get(`opt_${experimentName}_variation`);
};

export const handleOptimizelyGTMTracking = (eventKeys: string[]) => {
  try {
    const optimizelyData: {
      Optimizely_Flag__c: string[];
      Optimizely_Rule__c: string[];
      Optimizely_Variation__c: string[];
    } = {
      Optimizely_Flag__c: [],
      Optimizely_Rule__c: [],
      Optimizely_Variation__c: [],
    };

    const optimizelyExperimentNames = getOptimizelyExperimentNames();

    optimizelyExperimentNames.forEach((experimentName) => {
      let variation = getOptimizelyExperimentVariation(experimentName);

      if (
        variation === ExperimentDecisions.OutOfExperiment &&
        typeof window === 'undefined'
      ) {
        return;
      }

      const cookiesHelper = new Cookies();
      const userId = cookiesHelper.get(`opt_${experimentName}_user`);

      if ('control' === variation.toLowerCase()) {
        variation = `${variation}-${experimentName}`;
      }

      optimizelyData.Optimizely_Flag__c.push(experimentName);
      optimizelyData.Optimizely_Rule__c.push(`${experimentName}_experiment`);
      optimizelyData.Optimizely_Variation__c.push(variation);

      eventKeys.forEach((eventKey) => {
        //@ts-ignore GTM extends window with dataLayer on the client
        window.dataLayer = window.dataLayer || [];

        //@ts-ignore GTM extends window with dataLayer on the client
        window.dataLayer.push({
          event: 'optimizely-conversion',
          'optimizely-conversion-event': eventKey,
          'optimizely-flagKey': experimentName,
          'optimizely-ruleKey': `${experimentName}_experiment`,
          'optimizely-variationKey': variation,
          'optimizely-userId': userId,
        });
      });
    });

    return optimizelyData;
  } catch (e) {
    console.log('e: ', e);
  }
};

export const getOptimizelyEventKeys = (formType: FormType) => {
  const eventKeys = ['form_fill'];

  const formTypeToEventMapping = {
    [FormType.DemoRequest]: 'demo_request',
    [FormType.PEODemoRequest]: 'PEO Demo Request',
    [FormType.ProductTour]: 'tour_request',
    [FormType.SelfGuidedTour]: 'tour_request',
    [FormType.VideoTour]: 'tour_request',
    [FormType.Webinar]: 'content_request',
    [FormType.Content]: 'content_request',
    [FormType.QuoteRequest]: 'quote_request',
  };

  if (formTypeToEventMapping[formType]) {
    eventKeys.push(formTypeToEventMapping[formType]);
  }

  return eventKeys;
};

export const postToOptimizelyProxy = async (eventKeys: string[]) => {
  const url = `${VERCEL_OPTIMIZELY_API_PATH}?eventKeys=${eventKeys.join(',')}`;

  return fetch(url);
};
