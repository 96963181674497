const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  process.env.VERCEL_ENV === 'preview';

const PROD_LOCALES = {
  EN_US: 'en-US',
  EN_CA: 'en-CA',
  FR_CA: 'fr-CA',
  EN_GB: 'en-GB',
  EN_AU: 'en-AU',
  EN_IE: 'en-IE',
  FR_FR: 'fr-FR',
  NL_NL: 'nl-NL',
  DE_DE: 'de-DE',
  ES_ES: 'es-ES',
  IT_IT: 'it-IT',
  PT_PT: 'pt-PT',
};

const BETA_LOCALES = {
  IT_IT: 'it-IT',
  PT_PT: 'pt-PT',
};

const LOCALES = {
  ...PROD_LOCALES,
  ...(isDevelopment ? BETA_LOCALES : {}),
};

const LOCALES_LIST = Object.values(LOCALES);

module.exports.PROD_LOCALES = PROD_LOCALES;
module.exports.BETA_LOCALES = BETA_LOCALES;
module.exports.LOCALES = LOCALES;
module.exports.LOCALES_LIST = LOCALES_LIST;
