// @TODO Deprecate
export const get30Days = () => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() + 30));
};

export const getXDayFromNow = (x: number) => {
  const date = new Date();
  return new Date(date.setDate(date.getDate() + x));
};

export const getXHoursFromNow = (h: number) => {
  const date = new Date();
  date.setTime(date.getTime() + h * 60 * 60 * 1000);
  return date;
};

export const dateToDateStr = (date: string | null) => {
  if (date) {
    let [month, day, year] = date.split(' ');

    const MONTHS = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12',
    };

    day = day.match(/\d+/)[0];
    day = Number.parseInt(day, 10) < 10 ? `0${day}` : day;

    return `${year}-${MONTHS[month]}-${day}`;
  }
};
