import React from 'react';
// import * as Sentry from '@sentry/nextjs'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  async componentDidCatch(error, errorInfo) {
    // stale builds and assets will throw errors on old tabs.
    if (error?.name === 'ChunkLoadError') {
      // our build version validator (on the document) will take care of reloading for us.
      // So if window.__STALE_BUILD_RELOAD__ is present, we can skip the error reporting (false alarm).
      if (window.__STALE_BUILD_RELOAD__) {
        return;
      }
      // if for some reason the build version validator missed it, lets reload here.
      window.__STALE_BUILD_RELOAD__ = true;
      window.location.reload(true);
      // no need to report this error
      return;
    }

    if (typeof error === 'string') {
      error = `Error Boundary Caught Error (${this.props.name}) ${error}`;
    } else {
      error.message = `Boundary Caught Error - ${error.message}`;
    }

    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // Sentry.withScope(scope => {
    //   scope.setExtras(errorInfo)
    //   scope.setTag('ERROR_BOUNDARY', true)
    //   Sentry.captureException(error)
    // })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className="max-w-450 mx-auto text-center">
          <h3>Ooops, something went wrong! We are working on it.</h3>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
