import fs from 'fs';
import path from 'path';
import fetch from 'node-fetch';
import { getGlobals } from './globals';
import { getAmplitudePageTypes } from './pages';
import { resolveReusables } from './_reusables';
import { LOCALES } from '../locales';
import {
  filterEmptyBlockNames,
  localize,
  localizeMeta,
  replacePartialValues,
} from './_wordpress';

/**
 * WARNING... THIS IS A JS COPY OF :helpers/network-helpers attemptWithRetries
 * These should be merged at some point. DO NOT maintain this one.
 */
export const attemptWithRetries = async (
  fn,
  retryLimit = 5,
  retriesLeft = retryLimit
) => {
  try {
    return await fn();
  } catch (err) {
    if (retriesLeft > 0) {
      return await attemptWithRetries(fn, retryLimit, retriesLeft - 1);
    } else {
      console.error(
        `Failed to run attempted function after ${retryLimit} attempts.\n`,
        err
      );
      throw err;
    }
  }
};

export const isValidNumber = (data) => {
  return !isNaN(Number(data));
};

export const checkIsDevelopment = () => {
  return (
    process.env.NODE_ENV === 'development' ||
    process.env.VERCEL_ENV === 'preview'
  );
};

const postTypes = [
  'career',
  'customerSpotlight',
  'event',
  'meetup',
  'page',
  'partial',
  'post',
  'recipe',
  'resourceTemp',
  'countryGuide',
  'webinar',
  'glossaryTerm',
];

const getPostPublishedLocales = (post) => {
  return (
    post?.meta?.global?._localesPublished ||
    post?.meta?.global?._locales || [LOCALES.EN_US]
  );
};

const getPostTranslatedLocales = (post) => {
  return post?.meta?.global?._locales || [];
};

export const implementCaching = async (filename, fnc, hardRefresh) => {
  const isVercel =
    !!process.env.VERCEL_ENV && process.env.VERCEL_ENV !== 'local';

  const prePath = isVercel ? '/tmp' : './cache';

  let cachedJson;
  try {
    cachedJson = fs.readFileSync(`${prePath}/${filename}`, 'utf8');
    cachedJson = JSON.parse(cachedJson);
  } catch (e) {}

  if (!cachedJson || hardRefresh) {
    const result = await fnc();
    fs.writeFileSync(`${prePath}/${filename}`, JSON.stringify(result));

    return result;
  } else {
    return cachedJson;
  }
};

/**
 * This file import `fs` indicating a server-side helper. However, this function is used in the client-side across WP
 * and NextJS applications.
 */
export async function fetchAPI(query, { variables } = {}, forceProd) {
  let wpUrl = process.env.WP_URL;
  let refreshToken = '';

  if (!wpUrl) {
    const isDevelopment = checkIsDevelopment();

    if (isDevelopment && !forceProd) {
      refreshToken = process.env.WP_AUTH_REFRESH_TOKEN_STAGING;
      wpUrl = process.env.WP_STAGING_URL;
    } else {
      refreshToken = process.env.WP_AUTH_REFRESH_TOKEN_PROD;
      wpUrl = process.env.WP_PROD_URL;
    }
  }

  try {
    const res = await fetch(
      `${wpUrl || 'https://live-rippling-hub.pantheonsite.io'}/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: refreshToken ? `Bearer ${refreshToken}` : undefined,
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      }
    );

    if (!res.ok) {
      const errorText = await res.text();
      throw new Error(
        `HTTP error ${res.status}: ${res.statusText} - ${errorText}`
      );
    }

    const json = await res.json();

    if (json.errors) {
      const errorMessages = json.errors
        .map((error) => error.message)
        .join(', ');
      console.error('GraphQL Errors:', errorMessages, query);
      throw new Error(`GraphQL Error: ${errorMessages}`);
    }

    return json.data;
  } catch (error) {
    console.error('Fetch API Error:', error.message);
    throw new Error(`Fetch API Error: ${error.message}`);
  }
}

export async function fetchAllPages(
  query,
  { forceProd, maxLimit, variables } = {}
) {
  const PAGE_SIZE = 50;
  let allData = [];
  let cursor = null;
  let hasNextPage = true;
  let alreadyFetched = 0;

  while (hasNextPage) {
    try {
      const data = await fetchAPI(
        query,
        { variables: { ...variables, first: PAGE_SIZE, after: cursor } },
        forceProd
      );
      const pageInfoKey = Object.keys(data).find((key) => data[key].pageInfo);

      const currentPageData = data[pageInfoKey];

      if (!currentPageData) {
        throw new Error('Unexpected response structure');
      }

      allData = allData.concat(currentPageData.nodes);
      cursor = currentPageData.pageInfo.endCursor;

      if (maxLimit) {
        alreadyFetched += currentPageData.nodes.length + PAGE_SIZE;
        hasNextPage = alreadyFetched >= maxLimit;
      } else {
        hasNextPage = currentPageData.pageInfo.hasNextPage;
      }
    } catch (error) {
      console.error('Pagination Error:', error.message);
      throw new Error(`Pagination Error: ${error.message}`);
    }
  }

  return allData;
}

export const fetchApiWithRetries = async (
  query,
  { variables } = {},
  forceProd
) => {
  const fetchHandler = async () =>
    await fetchAPI(query, { variables }, forceProd);

  const attemptedResult = await attemptWithRetries(fetchHandler);

  if (!attemptedResult) {
    throw new Error(
      `Failed to fetch and parse. Error output should be above this message. ${JSON.stringify(
        { query, variables },
        undefined,
        4
      )}`
    );
  }

  return attemptedResult;
};

export const parseJSONS = (post) => {
  const blocks = post.blocks ? JSON.parse(post.blocks) : null;
  const meta = post.meta ? JSON.parse(post.meta) : null;
  const data = post.data ? JSON.parse(post.data) : null;
  const partials = post.partials ? JSON.parse(post.partials) : null;
  const wp = post.wp ? JSON.parse(post.wp) : null;

  return {
    ...post,
    blocks,
    meta,
    data,
    partials,
    wp,
  };
};

export async function getPartial(slug) {
  const data = await fetchApiWithRetries(`
    query Partial {
      partial(id: "${slug}", idType: SLUG) {
        meta(locale: EN_US)
      }
    }
  `);

  return data;
}

export async function getAuthorDataPartial(slug) {
  const rawPartial = await getPartial(slug);
  const parsedMeta = JSON.parse(rawPartial.partial.meta);
  return parsedMeta.authorData;
}

export async function getBlogHomePageConfig() {
  const data = await fetchApiWithRetries(`
    query Partial {
      partial(id: "blog-home-index", idType: SLUG) {
        meta
      }
    }
  `);

  return JSON.parse(data.partial.meta);
}

const pagesToExclude = [
  'select-quote-unity',
  'select-quote-broker-unity',
  'select-quote-accountant-unity',
  'select-quote/accountant',
  'select-quote/broker',
  'select-quote',
];

export const getWpLocaleEnum = (locale) => {
  const [lang, country] = locale.split('-');
  return `${lang.toUpperCase()}_${country.toUpperCase()}`;
};

const getPostDatabaseId = (type, slug) => {
  // pages without slugs will use database ids. these are only allowed in non-prod environments
  if (checkIsDevelopment() && isValidNumber(slug)) {
    return 'DATABASE_ID';
  }
  // "page" Wp types have different "idTypes" for slugs
  if (type === 'page') {
    return 'URI';
  }
  return 'SLUG';
};

const isMoreThan30MinutesFromDeploy = () => {
  const thirtyMinutes = 1800000;
  const timeSinceLastDeploy =
    new Date().getTime() - parseInt(process.env.DEPLOY_DATE);
  return timeSinceLastDeploy > thirtyMinutes;
};

export const shouldFetchPost = () => {
  return (
    process.env.VERCEL_ENV !== 'production' ||
    (process.env.VERCEL_ENV === 'production' && isMoreThan30MinutesFromDeploy())
  );
};

const pullPostFromPrebuildCache = (slug, locale, type) => {
  try {
    let pulledPost = fs.readFileSync(
      path.resolve(`./cache/${type}s/${slug}.json`),
      { encoding: 'utf-8' }
    );

    pulledPost = JSON.parse(pulledPost);

    const post = parseJSONS(pulledPost);
    post.blocks = replacePartialValues(localize(post.blocks, locale));
    post.blocks = filterEmptyBlockNames(post.blocks);
    post.meta = localizeMeta(post.meta, locale);

    return post;
  } catch (e) {
    console.log('e: ', e);
    // console.log(`Failed to pull post ${slug} from pre-build cache`)
    return null;
  }
};

export const trimSlug = (slug) =>
  !/part-\d+$/.test(slug) ? slug.replace(/-\d+$/, '') : slug;

const fetchPost = async ({ type, slug, idType, locale }) => {
  const fields = `
    modified
    blocks(locale: $locale)
    data(locale: $locale)
    meta(locale: $locale)
    partials(locale: $locale)
    wp
    ${type === 'page' ? 'useNewBlocks' : ''}
  `;
  let response;

  if (type === 'post') {
    const res = await fetchAPI(
      `
      query Post($locale: LocaleEnum) {
        posts(where: {slugContains: "${trimSlug(
          slug
        )}", tagSlugIn: "${locale}"}) {
          nodes {
            modified
            blocks(locale: $locale)
            meta(locale: $locale)
            partials(locale: $locale)
            wp
          }
        }
      }
    `,
      { variables: { locale: getWpLocaleEnum(locale) } }
    );

    response = {
      post: res?.posts?.nodes?.[0],
    };
  } else {
    response = await fetchApiWithRetries(
      `
      query Post($locale: LocaleEnum) {
        post: ${type}(id: "${slug}", idType: ${idType}) {
          ${fields}
        }
      }
    `,
      { variables: { locale: getWpLocaleEnum(locale) } }
    );
  }

  if (!response.post) {
    return null;
  }

  try {
    const parsePost = parseJSONS(response.post);
    parsePost.meta = localizeMeta(parsePost.meta, locale);
    return parsePost;
  } catch (error) {
    console.error('An error occurred:', error.message);
    return parseJSONS(response.post);
  }
};

const retrievePost = async ({ type, slug, idType, locale }) => {
  let post;

  const runFetchPost = async () =>
    await fetchPost({ type, slug, idType, locale });
  const runPullPost = () => pullPostFromPrebuildCache(slug, locale, type);

  const fetchIfRecipe = type === 'recipe';

  if (shouldFetchPost() || fetchIfRecipe) {
    post = await runFetchPost();
    if (!post) {
      post = runPullPost();
    }
  } else {
    post = runPullPost();
    if (!post) {
      post = await runFetchPost();
    }
  }

  return post;
};

const shouldRefetchPostInAvailableLocale = ({
  publishedLocales,
  requestedLocale,
}) => {
  return !publishedLocales.includes(requestedLocale);
};

const shouldRefetchWithEnUsContent = ({ translatedLocales, fetchedLocale }) => {
  // if en-us was requested, theres nothing to do
  if (fetchedLocale === LOCALES.EN_US) {
    return false;
  }
  // if requested locale is not in translated locales... then we want the en-US content only
  return !translatedLocales.includes(fetchedLocale);
};

const getPostFallbackLocale = (publishedLocales) => {
  // we want to try and show en-US content for any missing locales.
  // in the case this is a foreign market page only (like en-GB), we'll show whatever is first available.
  // we may want to revisit this in the future - showing Country content in any language vs en-US first.
  return publishedLocales.includes(LOCALES.EN_US)
    ? LOCALES.EN_US
    : publishedLocales[0];
};

const wpPostResponseIsValid = ({ type, post }) => {
  // if reponse was falsy (empty)
  if (!post) {
    return false;
  }
  // if reponse is non empty, but has no blocks
  if (type === 'page' && Array.isArray(post.blocks) && post.blocks === 0) {
    return false;
  }

  return true;
};

export const getPost = async ({
  type,
  slug,
  locale,
  overrideContentLocale = null,
  pageLocaleDefaulted = false,
}) => {
  if (pagesToExclude.includes(slug)) {
    return null;
  }

  const idType = getPostDatabaseId(type, slug);
  // the override content locale works silently...
  const localeToFetch = overrideContentLocale || locale;

  const post = await retrievePost({
    type,
    slug,
    idType,
    locale: localeToFetch,
  });

  if (type === 'post') {
    const res = await fetchAPI(
      `
      query Post($locale: LocaleEnum) {
        posts(where: {slugContains: "${trimSlug(
          slug
        )}", tagSlugIn: "${locale}"}) {
          nodes {
            data(locale: $locale)
          }
        }
      }
    `,
      { variables: { locale: getWpLocaleEnum(locale) } }
    );

    const parsedPosts = res.posts.nodes.map((post) => parseJSONS(post));
    const sameCategoryPosts = parsedPosts[0]?.data.sameCategoryPosts ?? [];

    post.data = { sameCategoryPosts };
  }

  if (!wpPostResponseIsValid({ type, post })) {
    return null;
  }

  // we add pageLocale here so we can track what locale content we're rendering
  // this should remain the requested page locale (locale) to that override content is seen as such
  // and we dont accidentally get the i18n not allowed banner.
  post.pageLocale = locale;
  // we want to track when this happens...
  // this supports the "locale" not available experience
  post.pageLocaleDefaulted = pageLocaleDefaulted;
  post.overrideContentLocale = overrideContentLocale;

  /**
   * STEP 1:
   * Lets decide if the requested locale is allowed for this page first...
   */
  const publishedLocales = getPostPublishedLocales(post);
  const shouldReplacePostLocale = shouldRefetchPostInAvailableLocale({
    publishedLocales,
    requestedLocale: locale,
  });
  // THIS SHOULD BE MOVED TO THE API LAYER...
  // if its not a published locale, lets replace it with the first available one...
  if (shouldReplacePostLocale && type !== 'post') {
    const replacementLocale = getPostFallbackLocale(publishedLocales);
    return await getPost({
      type,
      slug,
      locale: replacementLocale,
      pageLocaleDefaulted: true,
    });
  }

  /**
   * STEP 2:
   * If locale is "published" (allowed) for this page, lets see if its translated too...
   */
  const translatedLocales = getPostTranslatedLocales(post);
  const postIsEnUsContentOnly = shouldRefetchWithEnUsContent({
    translatedLocales,
    fetchedLocale: localeToFetch,
  });
  // if the locale is not a "translated" locale, we'll want to refetch with only en-US content.
  if (postIsEnUsContentOnly && type !== 'post') {
    return await getPost({
      type,
      slug,
      locale,
      // the override content locale works silently...
      overrideContentLocale: LOCALES.EN_US,
      pageLocaleDefaulted,
    });
  }

  return post;
};

export const getData = async ({
  query,
  type,
  slug,
  locale,
  previewData,
  ...options
}) => {
  return await query({
    ...options,
    slug,
    type,
    locale,
    previewData,
  });
};

export const getWPData = async ({
  type,
  slug,
  locale,
  previewData,
  ...options
}) => {
  const query = postTypes.includes(type) ? getPost : getPosts;

  return await getData({ ...options, query, type, slug, locale, previewData });
};

const sortEvents = (events) => {
  const sortedEvents = events.sort((a, b) => {
    const dateA = a.meta.events.eventDate;
    const dateB = b.meta.events.eventDate;

    if (!dateA || !dateB) {
      return 0;
    }

    return Date.parse(dateA) - Date.parse(dateB);
  });

  return sortedEvents;
};

const filterAndCategorizeWebinars = (webinars, isCustomerWebinars) => {
  const categorizedWebinars = {
    upcoming: [],
    'on-demand': [],
  };

  webinars = webinars.filter((webinar) => {
    if (
      webinar.meta.webinars._indices &&
      webinar.meta.webinars._indices.includes(
        isCustomerWebinars ? 'customers' : 'default'
      )
    ) {
      return true;
    }
    return false;
  });

  webinars.forEach((webinar) => {
    if (webinar.meta.webinars._type === 'on-demand') {
      categorizedWebinars['on-demand'].push(webinar);
    } else {
      categorizedWebinars.upcoming.push(webinar);
    }
  });

  const months = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  };

  const filteredUpcomingWebinars = categorizedWebinars.upcoming
    .filter((webinar) => {
      if (!webinar.meta.webinars.webinarDate) {
        return false;
      }
      let [month, dayWithComma, year] = webinar.meta.webinars.webinarDate.split(
        ' '
      );
      month = months[month];

      if (month) {
        const day = dayWithComma.replace(',', '');

        const newDate = new Date(
          parseInt(year),
          parseInt(month),
          parseInt(day)
        );
        const currentDate = new Date();

        // Future dates logic
        if (newDate.getTime() > currentDate.getTime()) {
          return true;
        }

        // Todays date logic
        if (
          newDate.getMonth() === currentDate.getMonth() &&
          newDate.getDate() === currentDate.getDate() &&
          newDate.getFullYear() === currentDate.getFullYear() &&
          new Date().getUTCHours() < 18
        ) {
          return true;
        }
      } else {
        return true;
      }
    })
    .sort((a, b) => {
      const dateA = a.meta.webinars.webinarDate;
      const dateB = b.meta.webinars.webinarDate;

      if (!dateA || !dateB) {
        return 0;
      }

      return Date.parse(dateA) - Date.parse(dateB);
    });

  categorizedWebinars.upcoming = filteredUpcomingWebinars;

  return categorizedWebinars;
};

const formatAuthorPartial = (authorPartial) => {
  const { meta, slug } = authorPartial;
  return {
    slug,
    ...meta.authorData,
  };
};

const getBlogPostAuthors = async () => {
  const res = await fetchApiWithRetries(
    `
    query Post {
      partials(where: {partialTag: "blog-post-author-data"}, first: 200) {
        nodes {
          meta(locale: EN_US)
          slug
        }
      }
    }
  `
  );

  const authorPartials = res.partials.nodes.map((authorData) =>
    parseJSONS(authorData)
  );

  return authorPartials.map(formatAuthorPartial);
};

const getBlogPostAuthorsMap = async () => {
  const authors = await getBlogPostAuthors();

  const authorsMap = authors.reduce((map, author) => {
    return {
      ...map,
      [author.slug]: author,
    };
  }, {});

  return authorsMap;
};

const populatePostAuthors = (post, authorsMap) => {
  if (!post.meta.blogPostData?._postAuthors?.length) {
    post.meta.blogPostData = post.meta.blogPostData || {};
    post.meta.blogPostData._postAuthors = ['the-rippling-team'];
  }

  const populatedPostAuthors = post.meta.blogPostData._postAuthors.map(
    (authorSlug) => {
      if (!authorsMap[authorSlug]) {
        console.log('\n\n\nmissing author data:', authorSlug);
        throw new Error(
          `Missing blog post author data for "${authorSlug}". Please make sure this published and has the correct tags associated.`
        );
      }
      return authorsMap[authorSlug];
    }
  );

  post.meta.blogPostData._postAuthorsDetails = populatedPostAuthors;

  return post;
};

export const getBlogPosts = async ({ limit, includePageContent, locale }) => {
  const isDevelopment = checkIsDevelopment();
  const where = `tagSlugIn: "${locale}", ${
    isDevelopment ? 'stati: [DRAFT, PUBLISH]' : 'stati: [PUBLISH]'
  }`;

  const res = await fetchApiWithRetries(
    `query Post($locale: LocaleEnum) {
      posts (first: ${limit}, where: {${where}}) {
        nodes {
          id
          wp
          meta(locale: $locale)
          ${includePageContent ? 'content(format: RAW)' : ''}
        }
      }
    }
  `,
    {
      variables: {
        locale: getWpLocaleEnum(LOCALES.EN_US),
      },
    }
  );

  const posts = res.posts.nodes.map((post) => parseJSONS(post));

  const authorsMap = await getBlogPostAuthorsMap();

  const populatedPosts = posts.map((post) =>
    populatePostAuthors(post, authorsMap)
  );

  return populatedPosts;
};

export const getEvents = async ({ type }) => {
  const isCustomerEvents = null;
  // we always want to use 'events' for both
  type = isCustomerEvents ? 'events' : type;

  const isDevelopment = checkIsDevelopment();

  const where = isDevelopment ? 'stati: [DRAFT, PUBLISH]' : 'stati: [PUBLISH]';

  const res = await fetchApiWithRetries(
    `query Events($locale: LocaleEnum) {
      events (first: 500, where: {${where}}) {
        nodes {
          wp
          meta(locale: $locale)
        }
      }
      secondary: constructs(type: "events", locale: $locale)
    }
  `,
    { variables: { locale: getWpLocaleEnum(LOCALES.EN_US) } }
  );

  const events = res[type].nodes.map((post) => parseJSONS(post));
  const categorizedEvents = sortEvents(events);

  const secondary = JSON.parse(res.secondary);
  const { partials, data } = secondary;

  return { categorizedEvents, partials, data };
};

export const getWebinars = async ({ type }) => {
  const isCustomerWebinars = type === 'customer-webinars';
  // we always want to use 'webinars' for both
  type = isCustomerWebinars ? 'webinars' : type;

  const isDevelopment = checkIsDevelopment();

  const locale = getWpLocaleEnum(LOCALES.EN_US);
  const statusArray = isDevelopment ? ['DRAFT', 'PUBLISH'] : ['PUBLISH'];
  const query = `
  query Webinars($locale: LocaleEnum, $status: [PostStatusEnum!]) {
    webinars(first: 500, where: { stati: $status }) {
      nodes {
        wp
        meta(locale: $locale)
      }
    }
    secondary: constructs(type: "webinars", locale: $locale)
  }
`;
  const variables = {
    locale: locale,
    status: statusArray,
  };
  const res = await fetchApiWithRetries(query, { variables });

  const webinars = res[type].nodes.map(parseJSONS);
  const categorizedWebinars = filterAndCategorizeWebinars(
    webinars,
    isCustomerWebinars
  );

  const secondary = JSON.parse(res.secondary);
  const { partials, data } = secondary;

  return { categorizedWebinars, partials, data };
};

export async function getPosts({ type, slug, locale, ...options }) {
  // we replaced this logic for posts with individual helpers
  if (type === 'posts') {
    return {
      posts: await getBlogPosts({
        limit: 1000,
        includePageContent: options?.includePageContent,
        locale,
      }),
      slug: slug || null,
    };
  }

  // handled by its own logic now (as an optimization)
  if (type === 'events' || type === 'customer-events') {
    const { categorizedEvents, partials, data } = await getEvents({
      type,
    });

    return {
      slug: slug || null,
      posts: categorizedEvents,
      data,
      partials,
    };
  } else if (type === 'webinars' || type === 'customer-webinars') {
    const { categorizedWebinars, partials, data } = await getWebinars({
      type,
    });

    return {
      slug: slug || null,
      posts: categorizedWebinars,
      data,
      partials,
    };
  }

  const isDevelopment = checkIsDevelopment();
  const where = isDevelopment ? 'stati: [DRAFT, PUBLISH]' : 'stati: [PUBLISH]';

  const nums = {
    recipes: 500,
    glossaryTerms: 500,
  };

  const res = await fetchApiWithRetries(
    `
    query Post($locale: LocaleEnum) {
      ${type}(first: ${
      nums[type] || 50
    }, where: {${where}, notIn: ${JSON.stringify(pagesToExclude)}}) {
        nodes {
          meta(locale: $locale)
          partials(locale: $locale)
          wp
          data(locale: $locale)
        }
      }
      secondary: constructs(type: "${type}", slug: "${slug}", locale: $locale)
    }
  `,
    { variables: { locale: getWpLocaleEnum(locale) } }
  );

  const posts = res[type].nodes.map((post) => {
    try {
      const parsePost = parseJSONS(post);
      parsePost.meta = localizeMeta(parsePost.meta, locale);
      return parsePost;
    } catch (error) {
      console.error('An error occurred:', error.message);
      return parseJSONS(post);
    }
  });

  // hiding until we get new customer index translated
  // if (['customerSpotlights'].includes(type)) {
  //   posts = posts.filter(post => {
  //     const postPublishedLocales = getPostPublishedLocales(post)
  //     return postPublishedLocales.includes(locale)
  //   })
  // }

  const secondary = JSON.parse(res.secondary);
  const { partials, data } = secondary;

  return {
    posts,
    partials,
    data,
    slug: slug || null,
  };
}

export const getMode = (construct, locale, isPagePreview) => {
  const isDevelopment = checkIsDevelopment();
  const status = construct.wp?.status;

  return {
    isDevelopment: isDevelopment || null,
    isPagePreview: isPagePreview || null,
    status: status || null,
    locale: locale || '',
    pageLocale: construct.pageLocale || '',
    pageLocaleDefaulted: construct.pageLocaleDefaulted || false,
    publishedLocales: getPostPublishedLocales(construct) || null,
    isMiniSite: construct?.meta?.global?._isMiniSite || false,
  };
};

const requiresAuth = ({ mode, construct, isAuthenticated }) => {
  return (
    construct?.meta?.global?.requiresAuth &&
    mode?.isDevelopment &&
    !isAuthenticated
  );
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const populateBlogPost = async (post) => {
  // set the default if none selected
  if (!post?.meta?.blogPostData?._postAuthors?.length) {
    // this is a worst case scenario. bandaid solution. We should validate against this in the future.
    if (!post?.meta?.blogPostData) {
      post.meta.blogPostData = {};
    }
    post.meta.blogPostData._postAuthors = ['the-rippling-team'];
  }

  const getAuthorDetails = async () => {
    const authorsArray = [];
    await asyncForEach(
      post.meta.blogPostData._postAuthors,
      async (authorSlug) => {
        const authorDetail = await getAuthorDataPartial(authorSlug);
        authorDetail.slug = authorSlug;
        authorsArray.push(authorDetail);
      }
    );
    post.meta.blogPostData._postAuthorsDetails = authorsArray;
  };

  const getRelatedArticles = async (post) => {
    const relatedPostKeys = ['_relatedPost3', '_relatedPost2', '_relatedPost1'];

    await asyncForEach(relatedPostKeys, async (key) => {
      if (
        post?.meta?.blogPostData[key] &&
        post?.meta?.blogPostData[key] !== 'Select a related post'
      ) {
        const relatedPost = await getPost({
          type: 'post',
          slug: post.meta.blogPostData[key],
          locale: post.pageLocale,
          overrideContentLocale: post.overrideContentLocale,
          pageLocaleDefaulted: post.pageLocaleDefaulted,
        });
        await getAuthorDetails(relatedPost);
        post.data.sameCategoryPosts.unshift(relatedPost);
      }
    });
  };

  await getAuthorDetails(post);
  await getRelatedArticles(post);
};

const should404DraftPost = (post, isDevelopment) => {
  const postIsDraft = post.wp?.status === 'draft';
  // if its a draft and we're in production, 404...
  return postIsDraft && !isDevelopment;
};

function convertECCSheetToJSON(csvJson) {
  const [headers, ...rows] = csvJson;
  const data = {};

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];
    const [country, province, territory, percentage, maximum] = row;

    if (!data[country]) {
      data[country] = {};
    }

    let territoryData = data[country];

    if (province) {
      if (!data[country][province]) {
        data[country][province] = {};
      }
      territoryData = data[country][province];
    }

    territoryData[territory] = {
      percentage: percentage,
      maximum: maximum !== 'null' ? maximum : null,
    };
  }

  return data;
}

const resolveDataBlock = async (blocks, locale) => {
  const result = [];

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];

    if (block.innerBlocks) {
      block.innerBlocks = await resolveDataBlock(block.innerBlocks, locale);
    }

    if (!['data', 'employee-cost-calculator'].includes(block.name)) {
      result.push(block);
      continue;
    }

    if (block.name === 'data') {
      const data = await fetchAPI(
        `
        query Data($locale: LocaleEnum) {
          ${block.attributes.query}
        }
        `,
        { variables: { locale: getWpLocaleEnum(locale) } }
      );

      block.attributes = { ...block.attributes, data };
    } else if (block.name === 'employee-cost-calculator') {
      const res = await fetchAPI(
        `
        query Globals($locale: LocaleEnum) {
          data: partial(id: "employee-cost-calculator", idType: SLUG) {
            meta(locale: $locale)
          }
          form: partial(id: "employee-cost-calculator-form", idType: SLUG) {
            blocks(locale: $locale)
          }
        }
        `,
        { variables: { locale: getWpLocaleEnum(locale) } }
      );

      const data = JSON.parse(res.data.meta).data.data;
      const form = JSON.parse(res.form.blocks);

      // const res2 = await fetch(
      //   'https://www-staging.rippling.com/api/www-vercel-google-sheet-employee-cost-calculator',
      //   {
      //     method: 'GET',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     redirect: 'follow',
      //   }
      // )

      // const json = await res2.json()

      // const sheetVals = convertECCSheetToJSON(json.result.values)

      // Object.keys(sheetVals).forEach(country => {
      //   if (data[country]) {
      //     data[country].rates = sheetVals[country]
      //   }
      // })

      block.attributes = { ...block.attributes, data, form };
    }

    result.push(block);
  }

  return result;
};

export const returnStaticProps = async ({
  query,
  type,
  slug,
  subPath,
  params,
  locale,
  preview,
  previewData,
  ...options
}) => {
  try {
    const isAuthenticated = previewData?.isAuthenticated || null;

    slug = params?.slug || slug;
    const args = { ...options, query, type, slug, locale, previewData };
    const construct = query ? await getData(args) : await getWPData(args);
    // if no data found
    if (!construct) {
      return { notFound: true };
    }

    const mode = getMode(construct, locale, preview);

    // if page is in draft (staging only)
    if (should404DraftPost(construct, mode?.isDevelopment)) {
      return { notFound: true };
    }

    if (requiresAuth({ construct, mode, isAuthenticated })) {
      return { props: { requiresAuth: true } };
    }

    // blog posts need "_postAuthors" populated
    if (type === 'post') {
      await populateBlogPost(construct);
    }

    // pull + populate reusables blocks
    construct.blocks = await resolveReusables(construct.blocks, false, locale);

    construct.blocks = await resolveDataBlock(construct.blocks, locale);

    const globals = await getGlobals({
      data: { construct, mode },
      type,
      slug,
      subPath,
      locale,
      previewData,
    });

    let amplitudePageTypes = null;
    if (type === 'page') {
      amplitudePageTypes = (await getAmplitudePageTypes()) || null;
      if (typeof window === 'undefined') {
        let CriticalCSSJSON;
        try {
          // CriticalCSSJSON = require('../cache/critical-css.json')
        } catch (e) {
          console.log('Critical json (file) failed to load.');
        }

        const pageCriticalCSS = CriticalCSSJSON?.[slug];

        if (pageCriticalCSS) {
          globals.head.styles = {
            ...globals.head.styles,
            criticalCSS: `${CriticalCSSJSON.global} ${pageCriticalCSS}`,
          };
        }
      }
    }

    return {
      props: {
        globals,
        construct,
        mode,
        amplitudePageTypes,
        previewData: previewData || null,
      },
    };
  } catch (err) {
    console.error(
      `Failed to revalidate page. Params: ${JSON.stringify({
        type,
        params,
        slug,
        locale,
        query,
      })}`,
      err
    );

    throw err;
  }
};

export async function getAllPostIds(type) {
  const isDevelopment = checkIsDevelopment();
  const stati = isDevelopment ? '[PUBLISH, DRAFT]' : '[PUBLISH]';

  const data = await fetchApiWithRetries(
    `
    query PageIds {
      ${type}(first: 300, where: {stati: ${stati}}) {
        nodes {
          slug
          ${type === 'recipes' ? 'meta' : ''}
          ${
            type === 'pages'
              ? `parent {
            node {
              slug
            }
          }`
              : ''
          }
        }
      }
    }
  `
  );

  if (type === 'recipes') {
    data[type].nodes = data[type].nodes.filter(
      (node) => JSON.parse(node.meta).pushToSite
    );
  }

  return data[type].nodes
    .filter(
      (post) =>
        !['resources', 'industries', 'home', 'select-quote'].includes(post.slug)
    )
    .map((post) => {
      let slug = [];
      const parentSlug = post.parent?.node?.slug;

      if (parentSlug) {
        slug.push(parentSlug);
      }

      slug.push(post.slug);

      if (
        !['pages', 'customerSpotlights', 'events', 'webinars'].includes(type)
      ) {
        slug = slug.join('/');
      }

      return {
        params: {
          slug,
        },
      };
    });
}

const getTopicsFromPosts = (posts) => {
  const categories = {};

  posts.forEach((post) => {
    post.wp.categories.forEach(({ slug, name }) => {
      categories[slug] = { slug, name };
    });
  });

  return Object.values(categories);
};

export async function getTopics() {
  const data = await fetchApiWithRetries(
    `
    query Categories {
      categories{
        nodes{
          name
          slug
        }        
      }
    }
  `
  );

  data.categories.nodes.forEach((node, index) => {
    if (data.categories.nodes[index].slug === 'uncategorized') {
      data.categories.nodes[index].slug = null;
      data.categories.nodes[index].name = 'All';
      data.categories.nodes[index].href = '/blog/topics';
      data.categories.nodes[index].weight = data.categories.nodes.length;
    } else {
      data.categories.nodes[
        index
      ].href = `/blog/topics?topics=${data.categories.nodes[index].slug}`;
      switch (data.categories.nodes[index].slug) {
        case 'for-hr':
          data.categories.nodes[index].name = 'HR';
          data.categories.nodes[index].weight = 1;
          break;
        case 'for-it':
          data.categories.nodes[index].name = 'IT';
          data.categories.nodes[index].weight = 2;
          break;
        case 'for-finance':
          data.categories.nodes[index].name = 'Finance';
          data.categories.nodes[index].weight = 3;
          break;
        case 'global':
          data.categories.nodes[index].weight = 4;
          break;
        case 'product':
          data.categories.nodes[index].weight = 6;
          break;
        case 'engineering':
          data.categories.nodes[index].weight = 7;
          break;
        case 'company':
          data.categories.nodes[index].weight = 5;
          break;
        case 'business-building':
          data.categories.nodes[index].name = 'Business';
          data.categories.nodes[index].weight = 8;
          break;
        case 'resources':
          data.categories.nodes[index].weight = 9;
          break;
      }
    }
  });

  data.categories.nodes.sort((a, b) => {
    return a.weight - b.weight;
  });

  return data;
}

export async function getAllAuthorIds(type) {
  const isDevelopment = checkIsDevelopment();
  const stati = isDevelopment ? '[PUBLISH, DRAFT]' : '[PUBLISH]';

  const data = await fetchApiWithRetries(
    `
      query Partials {
        partials(first: 300, where: {stati: ${stati}}) {
          nodes {
            slug     
            tags   
          }
        }
      }
    `
  );
  const authorPartials = [];
  data?.partials?.nodes.forEach((node) => {
    if (node?.tags) {
      const arr = JSON.parse(node.tags);
      Object.keys(arr[0]).forEach((key) => {
        if (arr[0][key] === 'blog-post-author-data' && key === 'slug') {
          authorPartials.push(node);
        }
      });
    }
  });

  return authorPartials;
}

export const returnStaticPaths = (paths) => {
  const isDevelopment = checkIsDevelopment();
  const isStaticExport = process.env.IS_STATIC_EXPORT;

  paths =
    (isDevelopment || true) && !process.env.NEXT_PUBLIC_IS_CIRCLECI
      ? []
      : paths;
  const fallback = isStaticExport ? false : 'blocking';

  return {
    paths,
    fallback,
  };
};
